<!--
 * @Description: 企业商品模块
 * @Author: zhang zhen
 * @Date: 2023-04-19 15:43:44
 * @LastEditors: zhang zhen
 * @LastEditTime: 2023-04-19 15:47:24
 * @FilePath: /page-sass/src/views/supplierInfo/modules/ProductList.vue
-->
<template>
  <div class="hotIndustry">
    <!-- <h3 class="hotIndustry-title">热门行业</h3> -->
    <div class="hotIndustry-list">
      <div class="hotIndustry-list-item" v-for="i in 4" :key="'infoCard' + i">
        <div class="hotIndustry-list-item-banner">
          <img :src="require('../../../assets/213x158.png')" alt="" />
        </div>
        <h3 class="subTitle">
          XXX产品
        </h3>
        <div class="design-desc">
          产品简介
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProductList',
  props: {
    infoData: {
      type: Array,
      default: () => ([])
    }
  },
  data() {
    return {}
  },
  methods: {
    handleNavigatePage(item) {
      this.$router.push('/productInfo')
    },
  }
}
</script>

<style lang="less" scoped>
.hotIndustry {
  width: 100%;
  // background: #f2f2f2;
  // padding: 15px 20px;
  border-radius: 6px;
  .flexLayout(@direction: column; @justifyContent: flex-start;);
  margin-bottom: 30px;
  //   &-title {
  //     color: #000;
  //     margin-bottom: 35px;
  //     font-size: 25px;
  //     font-weight: bolder;
  //   }
  &-list {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .calcWidth(60);
    // padding: 0 @length;
    &-item {
      width: 285px;
      padding: 15px;
      background: #fff;
      position: relative;
      cursor: pointer;
      margin-bottom: 30px;
      border: 1px solid #e8e8e8;
      &:hover {
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      }
      &-banner {
        width: 100%;
        padding: 0 20px;
        margin-bottom: 100px;
        img {
          width: 100%;
        }
      }
    }
  }
  .subTitle {
    font-size: 18px;
    margin-bottom: 15px;
    color: #505050;
  }
  .design-desc {
    color: #787878;
    text-indent: 40px;
    padding: 0 15px;
    height: 120px;
    .text-ellipsis-multi(5);
    line-height: 25px;
  }
}

@media (max-width: 1205px) {
  .hotIndustry {
    &-list {
      padding: 0 10px;
    }
  }
}

@media (max-width: 1054px) {
  .hotIndustry {
    &-list {
      justify-content: space-evenly;
      padding: 0;
    }
  }
}
</style>
