<!--
 * @Description: 供应商详情
 * @Author: zhang zhen
 * @Date: 2023-04-19 14:15:52
 * @LastEditors: zhang zhen
 * @LastEditTime: 2023-04-19 15:54:29
 * @FilePath: /page-sass/src/views/supplierInfo/index.vue
-->
<template>
  <div class="supplierInfo">
    <div class="card-container">
      <a-tabs type="card">
        <a-tab-pane key="1" tab="公司介绍">
          <div class="mainInfo-cover">
            <img
              src="https://zjjcmspublic.oss-cn-hangzhou-zwynet-d01-a.internet.cloud.zj.gov.cn/jcms_files/jcms1/web2745/site/picture/0/58a3d5b9846e4fbbbf56fcdb633ffe87.jpg"
              alt=""
              class="cover-img"
            />
            <div class="mainInfo-cover-content">
              东莞市莞恩纸品有限公司创业于2008年，2019年迁入寮步良边现代化标准厂房，主要产品包括各种环保纸箱、啤盒、展示盒、平卡、刀卡、纸卡板、蜂窝栈板、纸护角等系列包装材料。
              工厂拥有分纸机、薄刀分纸机、双色印刷机、双色全自动印刷机、全自动、半自动开槽机、打角机、啤机、全自动啤机、钉机等设备。我们务实经营得以良好的发展，现拥有一批精干的管理人员和一支高素质的专业技术队伍，人数50人，也有舒适优雅的办公环境和现代化生产经营场所，日常经营过程中我们严控质量关，市场范围不断扩大，销售金额已到4000万/年。东莞市莞恩纸品有限公司以诚信、实力和质量获得业界的认可。
            </div>
          </div>
          <a-card hoverable style="width: 100%; margin-top: 15px;" title="公司视频">
            <video
              src="https://anyi-safe.oss-cn-shanghai.aliyuncs.com/test/2023-04-19/mp4/89289c0f-edfd-4ec3-a2ed-361f9adf9c0e.mp4"
              width="100%"
              class="video-info"
              height="450px"
              autoplay
              muted
              loop
            ></video>
          </a-card>
          <a-card hoverable style="width: 100%; margin-top: 15px;" title="企业能力">
            <a-descriptions title="基本信息">
              <a-descriptions-item label="加工方式">
                来样加工
              </a-descriptions-item>
              <a-descriptions-item label="工艺">
                分切
              </a-descriptions-item>
              <a-descriptions-item label="管理认证体系">
                IOS10005123
              </a-descriptions-item>
            </a-descriptions>
          </a-card>
        </a-tab-pane>
        <a-tab-pane key="2" tab="供应产品">
          <ProductList />
        </a-tab-pane>
        <a-tab-pane key="3" tab="荣誉资质">
          <div class="moreImgs">
            <img
              v-for="i in 4"
              :key="i"
              src="https://www.generalwatertech.com/uploadfiles/2020/01/20200109175314541.jpg?MS5qcGc="
              alt=""
            />
          </div>
        </a-tab-pane>
        <a-tab-pane key="4" tab="公司相册">
          <a-card hoverable style="width: 100%; margin-top: 15px;" title="公司相册">
            <div class="moreImgs">
              <img
                v-for="i in 4"
                :key="i"
                src="https://www.generalwatertech.com/uploadfiles/2020/01/20200109175314541.jpg?MS5qcGc="
                alt=""
              />
            </div>
            <div class="pages">
                <a-pagination size="small" :total="50" show-size-changer show-quick-jumper />
            </div>
          </a-card>
        </a-tab-pane>
      </a-tabs>
    </div>
  </div>
</template>

<script>
import ProductList from './modules/ProductList.vue'
export default {
  name: 'supplierInfo',
  components: {
    ProductList
  },
  data() {
    return {}
  }
}
</script>

<style lang="less" scoped>
.supplierInfo {
  width: 1190px;
  min-width: 990px;
  margin: 0 auto;
  padding: 30px 0;
  .flexLayout(@direction: column; @justifyContent: flex-start;);
  .card-container {
    width: 100%;
    background: #f5f5f5;
    overflow: hidden;
  }
  ::v-deep .ant-tabs-card-bar {
    margin-bottom: 0;
  }
  ::v-deep .ant-tabs-nav-container {
    background-color: #fff;
    // border-bottom: 1px solid #e8e8e8;
  }
  .card-container > .ant-tabs-card > .ant-tabs-content > .ant-tabs-tabpane {
    background: #fff;
    padding: 16px;
  }

  .card-container > .ant-tabs-card > .ant-tabs-bar {
    border-color: #fff;
  }

  .card-container > .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab {
    border-color: #fff;
    background: transparent;
  }

  .card-container > .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab-active {
    border-color: #fff;
    background: #fff;
  }
  ::v-deep .ant-tabs-tab {
    border-radius: 0 !important;
    margin-right: 0 !important;
    border-left: none !important;
  }
  .mainInfo-cover {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px;
    .cover-img {
      width: 340px;
    }
    &-content {
      flex: 1 0 0;
      text-indent: 40px;
      color: #333;
      white-space: break-spaces;
      font-size: 14px;
      margin-left: 20px;
    }
  }
  .video-info {
    object-fit: contain;
  }
  .pages {
    text-align: center;
  }
  .moreImgs {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    grid-gap: 30px;
    padding: 40px;
    img {
      width: 200px;
      height: auto;
    }
  }
}
@media (max-width: 1349px) {
  .supplierInfo {
    width: 990px;
  }
}
</style>
